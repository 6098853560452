export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  methods: {
    browsePost() {
      this.$router.push({
        name: "ConstructorBlogArticle",
        params: { slug: this.post.slug },
        });
    }
  }
}