<template>
  <a @click="Clicking" class="thumb-post-constructor">
    <div class="thumb-post-constructor__figure">
      <img :src="post.featured_image" />
    </div>
    <div class="thumb-post-constructor__content">
      <h2 class="thumb-post-constructor__title">
        {{ post.title }}
      </h2>
      <div class="thumb-post-constructor__tax">
        {{ post.mainCategory }}
      </div>
    </div>
  </a>
</template>

<script>
import Post from "./Mixins/Post";

export default {
  name: "BlogPostCard",
  mixins: [Post],
  methods: {
    Clicking() {
      this.$emit("clicked", this.post.title);
      this.browsePost();
    },
  },
};
</script>
